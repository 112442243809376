import { Alert, LinearProgress } from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import { Dictionary } from "types";

type LibraryLoaderProps = {
    scripts: string[];
    styles: string[];
} & PropsWithChildren;

export function LazyLibrary({ scripts, styles, children }: LibraryLoaderProps) {

    const [state, setState] = useState<"ready" | "error" | "loading">("loading");

    useEffect(() => {
        if (state !== "loading")
            setState("loading");

        const scriptPromises = (scripts || []).map(loadScript);
        const stylesPromises = (styles || []).map(loadStyle);

        Promise.all([...scriptPromises, ...stylesPromises])
            .then(() => setState("ready"))
    }, [scripts, styles]);

    if (state === "error")
        return <Alert severity="error">Could not load file preview</Alert>;

    if (state === "loading")
        return <LinearProgress />;

    if (state === "ready")
        return <>{children}</>;
}

export function loadScript(url: string, id: number) {

    return loadFile(url, getElement, createElement);

    function getElement() {
        return document.querySelector(`script[src="${url}"]`);
    }

    function createElement() {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.async = false;
        return script;
    }
}

export function loadStyle(url: string) {

    return loadFile(url, getElement, createElement);

    function getElement() {
        return document.querySelector(`link[href="${url}"]`);
    }

    function createElement() {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = url;
        return link;
    }
}

declare global {
    var loadedScripts: Dictionary<boolean>;
}

export function loadFile(url: string, elementSelector: () => Element, elementFactory: () => Element) {

    if (!window.loadedScripts)
        window.loadedScripts = {};

    return new Promise<string>((resolve, reject) => {

        let element = elementSelector();

        //file has not been added to the dom yet
        if (!element) {
            element = elementFactory();
            document.head.appendChild(element);
        }

        //file has been added to the dom, but is not loaded yet
        if (!window.loadedScripts[url]) {
            element.addEventListener("load", onLoad);
            element.addEventListener("error", onError);
        }

        //file is added to the dom and loaded
        else {
            resolve(url);
        }

        function onLoad() {
            window.loadedScripts[url] = true;
            element.removeEventListener("load", onLoad);
            element.removeEventListener("error", onError);
            resolve(url);
        }
        function onError(error: ErrorEvent) {
            element.removeEventListener("load", onLoad);
            element.removeEventListener("error", onError);
            reject(url);
        }
    })
}